import { Injectable } from '@angular/core';
let allyhealthLogo = require("../../assets/logo.png");
let bnLogo = require("../../assets/bnlogo.png");

@Injectable({
  providedIn: 'root'
})
export class FlavourDomainService {

  constructor() { }
  domain(){
    if(window.location.host.includes('localhost') || window.location.host=='10.5.7.28:9200'){
      // change default BN to allyhealth :-  return  {'name':"Benefits Navigator",'key':'BN','logo':bnLogo}
      return {'name':"Allyhealth",'key':'allyhealth','logo':allyhealthLogo}
    }
    if(window.location.host.includes('benefitsnavigator')){
      return  {'name':"Benefits Navigator",'key':'BN','logo':bnLogo}
      
    }
    if(window.location.host.includes('allyhealth')){
      return {'name':"Allyhealth",'key':'allyhealth','logo':allyhealthLogo}
      
    }
  }
}
