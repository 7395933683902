import { Injectable } from '@angular/core';
import { Observable} from 'rxjs/Observable'
import { Headers } from '@angular/http';
import { Http } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import * as CryptoJS from 'crypto-js';
import { FlavourDomainService } from './flavour-domain.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class HttpClientService {
 
 public authToken ; 
 
 constructor(private httpClient: HttpClient,private http:Http,private cookieService:CookieService, private FlavourDomain:FlavourDomainService){}


 public hmacConvert(method, data) {
    var hmacKey = '/&+@spJ8;*x+%4(9%P_n=Fu[a()F^%t.<P5sqHEp5~a.<M{hFa';
    var string = '';
    string = `${hmacKey}-${method}-${data !== "" ? JSON.stringify(data) : ""}`
    
    var hash = CryptoJS.HmacSHA256(string, hmacKey);
    var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
    return hashInBase64;
 }

 createAuthorizationHeader(headers: Headers, signKey) 
 {
     this.authToken =this.cookieService.get('token');
    if(this.cookieService.get('deviceInfo')){

        var deviceInfo = JSON.parse(this.cookieService.get('deviceInfo'));
    }

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', 'token ' + this.authToken);
    if(this.cookieService.get('deviceInfo')){

        headers.append('browser-name',deviceInfo.browser);
        headers.append('browser-version', deviceInfo.browser_version);
        headers.append('platform', deviceInfo.os);
    }
    headers.append('Signing-Key', signKey);
    headers.append('app', this.FlavourDomain.domain().key);
 }

 createUcmsAuthorizationHeader(headers: Headers) 
 {
    this.authToken =this.cookieService.get('token');

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Token 496029BF-4793-44B7-AAC0-1D53CCB0323C');
    headers.append('_WPOSUBSCRIBER', 'ALLYH');
 }
 createWpoAuthorizationHeader(headers: Headers) 
 {
    headers.append('Content-Type', 'application/json');
    headers.append('Ocp-Apim-Subscription-Key', 'aae8b416935045b0b081248273571653');
    
 }
 contentheaderSet(headers: Headers) 
 {
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    
 }

 createAuthorizationHeaderFormdat(headers: Headers, signKey) 
 {
    this.authToken =this.cookieService.get('token');
    if(this.cookieService.get('deviceInfo')){

        var deviceInfo = JSON.parse(this.cookieService.get('deviceInfo'));
    }

    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.delete('Content-Type');
    headers.append('Authorization', 'token ' + this.authToken);
    if(this.cookieService.get('deviceInfo')){

        headers.append('browser-name',deviceInfo.browser);
        headers.append('browser-version', deviceInfo.browser_version);
        headers.append('platform', deviceInfo.os);
    }
    headers.append('Signing-Key', signKey);
    headers.append('app', this.FlavourDomain.domain().key);
 }


 createAuthorizationHeaderwithoutToken(headers: Headers, signKey) 
 {
    if(this.cookieService.get('deviceInfo')){

        var deviceInfo = JSON.parse(this.cookieService.get('deviceInfo'));
    }
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    if(this.cookieService.get('deviceInfo')){

        headers.append('browser-name',deviceInfo.browser);
        headers.append('browser-version', deviceInfo.browser_version);
        headers.append('platform', deviceInfo.os);
    }
   
    headers.append('Signing-Key', signKey);
    headers.append('app', this.FlavourDomain.domain().key);
 }

 createAuthorizationHeaderwithoutMDliveToken(headers: Headers) 
 {
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('app', 'BN');
 }

 createAuthorizationHeaderwithMDlivetoken(headers: Headers) 
 {
    var mdlive =JSON.parse(this.cookieService.get('mdlive'));
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.delete('Content-Type');
    headers.append('Authorization', 'Bearer ' + mdlive.m_jwt_token);
    headers.append('app', 'BN');

 }

 createAuthorizationHeaderwithoutDDNToken(headers: Headers) 
 {
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
 }

 createAuthorizationHeaderwithDDNtoken(headers: Headers) 
 {

    var DDNToken =this.cookieService.get('ddnToken');
    headers.append('Content-Type', 'multipart/form-data');
 
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.delete('Content-Type');
    headers.append('Authorization', 'Bearer ' + DDNToken);
    
 }


 PostLogin(url,data) 
 {
    const headers = new Headers(); 
    let hKey = this.hmacConvert('POST',data);
    this.createAuthorizationHeaderwithoutToken(headers, hKey); 
    return this.http.post(url, data,
        {
            headers: headers,
        }
    )
    .map(res => res.json());
 }

 putImage(url,data){
    const headers = new Headers(); 
    let hKey = this.hmacConvert('PUT',data);
    this.createAuthorizationHeaderFormdat(headers, hKey); 
    return this.http.put(url, data,
        {
            headers: headers,
        }
    )
    .map(res => res.json());
 }

 postImage(url,data){
    const headers = new Headers(); 
    let hKey = this.hmacConvert('POST',data);
    this.createAuthorizationHeaderFormdat(headers,hKey); 
    return this.http.post(url, data,
        {
            headers: headers,
        }
    )
    .map(res => res.json());
 }

 getWT(url){
    const headers = new Headers(); 
    let hKey = this.hmacConvert('GET','');
    this.createAuthorizationHeaderwithoutToken(headers, hKey); 
    return this.http.get(url, 
        {
            headers: headers,
        }
    )
    .map(res => res.json());  
 }
    
 get(url) 
 {
    const headers = new Headers(); 
    let hKey = this.hmacConvert('GET','');
    this.createAuthorizationHeader(headers,hKey); 
    return this.http.get(url, 
        {
            headers: headers,
        }
    )
    .map(res => res.json());
 }

  
  
  
 getUcms(url) 
 {
    const headers = new Headers(); 
    this.createUcmsAuthorizationHeader(headers); 
    return this.http.get(url, 
        {
            headers: headers,
        }
    )
    .map(res => res.json());
 }
 postUcms(url,data) 
 {
    const headers = new Headers(); 
    this.createUcmsAuthorizationHeader(headers); 
    return this.http.post(url, data,
        {
            headers: headers,
        }
    )
    .map(res => res.json());
 }

 postWpo(url,data) 
 {
    const headers = new Headers(); 
    this.createWpoAuthorizationHeader(headers); 
    return this.http.post(url, data,
        {
            headers: headers,
        }
    )
    .map(res => res.json());
 }

postContent(url, data){
    return this.http.post(url, data)
    .map(res => res.json());
}
postContentwithHeader(url, data){
    const headers = new Headers(); 
    this.contentheaderSet(headers)
    return this.http.post(url, data,{
        headers: headers,
        })
    .map(res => res.json());
}

 get_lat_log_zipcode(url) 
 {
    const headers = new Headers(); 
    return this.http.get(url, 
        {
            headers: headers,
        }
    )
    .map(res => res.json());
 }

 post(url, data) 
 {
    const headers = new Headers(); 
    let hKey = this.hmacConvert('POST',data);
    this.createAuthorizationHeader(headers, hKey);
    return this.http.post(url, data, 
        {
        headers: headers,
        }
    )
    .map(res => res.json());
 }
 
 put(url, data) 
 {
    const headers = new Headers();
    let hKey = this.hmacConvert('PUT',data);
    this.createAuthorizationHeader(headers,hKey);
    return this.http.put(url, data, 
        {
            headers: headers,
        }
    )
    .map(res => res.json());
 }
 
 delete(url) 
 {
    const headers = new Headers();
    let hKey = this.hmacConvert('DELETE','');
    this.createAuthorizationHeader(headers,hKey);
    return this.http.delete(url,
        {
        headers: headers,
        }
    )
    .map(res => res.json());
 }

/*********************************MDliive*************************************************************** */
getM(url) 
{
   const headers = new Headers(); 
   this.createAuthorizationHeaderwithMDlivetoken(headers); 

   return this.http.get(url, 
       {
           headers: headers,
       }
   )
   .map(res => res.json());

}

postM(url, data) 
{
   const headers = new Headers(); 
   this.createAuthorizationHeaderwithMDlivetoken(headers);
   return this.http.post(url, data, 
       {
       headers: headers,
       }
   )
   .map(res => res.json());
}
putM(url, data) 
{
   const headers = new Headers();
   this.createAuthorizationHeaderwithMDlivetoken(headers);
   return this.http.put(url, data, 
       {
           headers: headers,
       }
   )
   .map(res => res.json());
}
patchM(url, data) 
{
   const headers = new Headers();
   this.createAuthorizationHeaderwithMDlivetoken(headers);
   return this.http.patch(url, data, 
       {
           headers: headers,
       }
   )
   .map(res => res.json());
}



deleteM(url) 
{
   const headers = new Headers();
   this.createAuthorizationHeaderwithMDlivetoken(headers);
   return this.http.delete(url,
       {
       headers: headers,
       }
   )
   .map(res => res.json());
}


postMwithOutToken(url, data) 
{
   const headers = new Headers(); 

   this.createAuthorizationHeaderwithoutMDliveToken(headers);
   return this.http.post(url, data, 
       {
           headers: headers,
       }
   )
   .map(res => res.json());
}

// ********************************DDN***************************************************
getDwithOutToken(url,data) 
{
   const headers = new Headers(); 

   this.createAuthorizationHeaderwithoutDDNToken(headers);
   return this.http.post(url, data,
       {
           headers: headers,
       }
   )
   .map(res => res.json());
}


getD(url) 
{
    const headers = new Headers(); 
    this.createAuthorizationHeaderwithDDNtoken(headers);
    return this.http.get(url,
        {

            headers: headers,
        }
    )
    .map(res => res.json());
}


createAuthorizationLyricToken(headers: Headers) 
{
    let data = localStorage.getItem("lyricData");
   var lyricToken =JSON.parse(data)?JSON.parse(data).lyric_usertoken:"";

   headers.append('Authorization', 'Bearer ' + lyricToken);
   
}

  
getLyricWithToken(url)
{
    const headers = new Headers(); 
    this.createAuthorizationLyricToken(headers);
    return this.http.get(url,
        {

            headers: headers,
        }
    )
    .map(res => res.json());
}

createAuthorizationLyricToken3(headers: HttpHeaders): HttpHeaders {
    let data = localStorage.getItem("lyricData");
    const lyricToken = JSON.parse(data).lyric_usertoken || ""; // Use optional chaining
  
    // Return a new HttpHeaders instance with the Authorization header
    return headers.append('Authorization', 'Bearer ' + lyricToken);
  }
// getLyricWithToken2(url: string): Observable<Blob> {
//     const headers = new HttpHeaders(); // Use HttpHeaders
//     this.createAuthorizationLyricToken3(headers); // Modify headers as needed
    
//     return this.http.get(url, {
//       headers: headers,
//       responseType: 'blob' // Specify response type
//     });
//   }

  
createAuthorizationLyricToken2(headers: HttpHeaders): HttpHeaders {
    const data = localStorage.getItem("lyricData");
    const lyricToken = JSON.parse(data) ? JSON.parse(data).lyric_usertoken : "";
    return headers.set('Authorization', 'Bearer ' + lyricToken);
}
// get(url) 
// {
//    const headers = new Headers(); 
//    let hKey = this.hmacConvert('GET','');
//    this.createAuthorizationHeader(headers,hKey); 
//    return this.http.get(url, 
//        {
//            headers: headers,
//        }
//    )
//    .map(res => res.json());
// }
createAuthorizationHeader2(headers: HttpHeaders, signKey: string): HttpHeaders {
    this.authToken = this.cookieService.get('token');

    // HttpHeaders are immutable, so you must reassign each time you append a header
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Authorization', 'token ' + this.authToken);
    headers = headers.set('Signing-Key', signKey);
    headers = headers.set('app', this.FlavourDomain.domain().key);
  
    return headers; // Return the modified headers
  }
  
  getImage(url: string): Observable<Blob> {
    let headers = new HttpHeaders();
    let hKey = this.hmacConvert('GET', '');
    headers = this.createAuthorizationHeader2(headers, hKey); 
  
    return this.httpClient.get(url, {
      headers: headers,
      responseType: 'blob'  // Indicate we expect binary data (blob)
    });
  }
  getImagePdf(url: string): Observable<Blob> {
    let headers = new HttpHeaders();
    let hKey = this.hmacConvert('GET', '');
    headers = this.createAuthorizationLyricToken3(headers); 
  
    return this.httpClient.get(url, {
      headers: headers,
      responseType: 'blob'  // Indicate we expect binary data (blob)
    });
  }
  

postLyricWithToken(url, data)
{
    const headers = new Headers(); 

   this.createAuthorizationLyricToken(headers);
   return this.http.post(url, data, 
       {
           headers: headers,
       }
   )
   .map(res => res.json());

}
}
